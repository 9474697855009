<template>
  <div id="mapContainer" class="basemap"></div>
</template>

<style>
.basemap{
  position: fixed;
  min-width: 100%;
  min-height: 100vh;
  z-index: -100;
}
</style>

<script>
import mapboxgl from 'mapbox-gl'

export default {
  name: 'BaseMap',
  data () {
    return {
      accessToken: 'pk.eyJ1IjoiaWtpMTMzNyIsImEiOiJjazFsMnJpNGIwMWI5M2lueXVydXB1a3g2In0.8bSCj5lTGb4K_KHagX4Caw'
    }
  },
  mounted () {
    mapboxgl.accessToken = this.accessToken
    const map = new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [-0.7624682482431145, 49.29289831532339],
      pitch: 45,
      zoom: 15
    })
    const marker = new mapboxgl.Marker()
      .setLngLat([-0.7624682482431145, 49.29289831532339])
      .addTo(map)
    const nav = new mapboxgl.NavigationControl()
    map.addControl(nav, 'bottom-right')
    console.log(marker)

    // Le code qui suit sert à l'effet 3D de la map

    map.on('load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers
      const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
      ).id

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      map.addLayer(
        {
          id: 'add-3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': '#aaa',

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
          }
        },
        labelLayerId
      )
    })
  }
}
</script>
