<template>
  <div class="contact">
    <Loader/>
    <BaseMap/>
    <div class="btnForm" @click="formActive()">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div class="form">
      <h2>Nous contacter</h2>
      <form action="">
        <div class="inputBx">
          <input type="text" id="nom" placeholder="Nom*" required>
        </div>
        <div class="inputBx">
          <input type="email" id="email" placeholder="Email*" required>
        </div>
        <div class="inputBx">
          <input type="objet" id="objet" placeholder="Objet*" required>
        </div>
        <div class="inputBx">
          <textarea name="message" id="message" placeholder="Votre Message...*" required></textarea>
        </div>
        <vue-hcaptcha @verify="verify()" class="text-center my-1 captcha" sitekey="3326e26b-f9a2-44ff-8690-2ed452c3953c"></vue-hcaptcha>
        <p>Les champs marqués d'un * sont obligatoires</p>
        <div class="inputBx">
          <button class="submit" @click="submit()">Envoyer</button>
        </div>
        <hr>
        <a href="tel:0604169917"><i class="fas fa-phone"></i> +33 (0)6 04 16 99 17</a> <br>
        <a href="mailto:contact@agence-brome.fr"><i class="fas fa-envelope"></i> contact@agence-brome.fr</a>
      </form>
    </div>
  </div>
</template>

<style>
.btnForm{
  position: fixed;
  width: 75px;
  height: 75px;
  background: rgba(255,255,255,0.1);
  border-radius: 50px;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgb(0,0,0,0.1);
  border: 1px solid rgb(255,255,255,0.5);
  border-right: 1px solid rgb(255,255,255,0.2);
  border-bottom: 1px solid rgb(255,255,255,0.2);
  top: 50%;
  right: 10px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 10;
}

.btnForm.active{
  transform: rotateY(180deg);
}

.btnForm svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color:white;
  font-size: 3em!important;
}

.form{
  position: absolute;
  width: 25%;
  min-width: 200px;
  padding: 40px;
  background: rgba(255,255,255,0.1);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgb(0,0,0,0.1);
  border: 1px solid rgb(255,255,255,0.5);
  border-right: 1px solid rgb(255,255,255,0.2);
  border-bottom: 1px solid rgb(255,255,255,0.2);
  top: 100px;
  left: -10%;
  transform: translate(-50%,0);
  text-align: center;
  transition: 1s;
  opacity: 0;
}

.form.active{
  left: 50%;
  opacity: 1;
}

.form h2{
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

.form p{
  color: #fff;
}

 .form .inputBx{
    width: 100%;
    margin-top: 20px;
 }

 .form .inputBx input{
     width: 80%;
     background: rgba(255,255,255,0.2);
     border: none;
     outline: none;
     padding: 10px 20px;
     border-radius: 35px;
     border: 1px solid rgb(255,255,255,0.5);
     border-right: 1px solid rgb(255,255,255,0.2);
     border-bottom: 1px solid rgb(255,255,255,0.2);
     font-size: 16px;
     letter-spacing: 1px;
     color: #fff;
     box-shadow: 0 5px 15px rgb(0,0,0,0.05);
 }

 .form .inputBx textarea{
    width: 80%;
    height: 140px;
    resize: none;
    background: rgba(255,255,255,0.2);
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid rgb(255,255,255,0.5);
    border-right: 1px solid rgb(255,255,255,0.2);
    border-bottom: 1px solid rgb(255,255,255,0.2);
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff !important;
    box-shadow: 0 5px 15px rgb(0,0,0,0.05);
}

.form .inputBx input::placeholder{
    color: #fff;
}

.form .inputBx textarea::placeholder{
    color: #fff;
}

.form .inputBx .submit{
    background: #fff;
    color: #666;
    padding: 10px;
    border-radius: 35px;
    max-width: 150px;
    cursor: pointer;
    margin: auto;
    font-weight: 600;
    transition: .5s ease-in-out;
}

.form .inputBx .submit:hover{
    background: rgba(255,255,255,0.2);
    color: #fff;
    font-weight: 600;
}

.text{
    margin-top: 5px;
    color: #fff;
}

.captcha{
  margin-top: 20px;
}

form hr{
  margin: 10px auto;
}

form a{
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
}

form a:hover{
  color: rgb(154, 0, 185);
}

@media screen and (max-width: 900px) {
  .form{
    position: inherit;
    width: 85%;
    padding: 10px;
    top: 100px;
    transform: translate(-50%,100px);
    margin-bottom: 150px;
  }

  .form.active{
    transform: translate(5%,100px);
  }
}

</style>

<script>
// @ is an alias to /src
import Loader from '@/components/Loader.vue'
import BaseMap from '@/components/BaseMap'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import axios from 'axios'

export default {
  components: {
    Loader,
    BaseMap,
    VueHcaptcha
  },
  data () {
    return {
      captcha: false
    }
  },
  mounted () {
    document.querySelector('.navCheckbox').checked = false
    setTimeout(
      function () {
        document.querySelector('#loader').style.display = 'none'
      }, 2300)
    window.scrollTo(0, 0)
  },
  methods: {
    formActive () {
      document.querySelector('.btnForm').classList.toggle('active')
      document.querySelector('.form').classList.toggle('active')
    },
    verify () {
      this.captcha = true
    },
    submit () {
      const nom = document.getElementById('nom').value
      const email = document.getElementById('email').value
      const objet = document.getElementById('objet').value
      const message = document.getElementById('message').value

      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.emailValide = true
      } else {
        document.getElementById('email').setCustomValidity('Champ invalide')
        this.emailValide = false
      }
      if (nom !== '') {
        this.nomValide = true
      } else {
        document.getElementById('nom').setCustomValidity('Champ invalide')
        this.nomValide = false
      }
      if (objet !== '') {
        this.objetValide = true
      } else {
        document.getElementById('objet').setCustomValidity('Champ invalide')
        this.objetValide = false
      }
      if (message !== '') {
        this.msgValide = true
      } else {
        document.getElementById('message').setCustomValidity('Champ invalide')
        this.msgValide = false
      }
      if (this.nomValide === true && this.emailValide === true && this.objetValide === true && this.msgValide === true && this.captcha === true) {
        const data = {
          nom,
          email,
          objet,
          message
        }
        axios
          .post('https://agence-brome.fr/api/email', data)
          .then(
            response => (console.log(response)),
            alert('Votre message a bien été envoyé !'),
            this.$router.push({ path: '/' })
          )
          .catch(function (error) {
            alert(error)
            console.log(error)
          })
      }
    }
  }
}
</script>
